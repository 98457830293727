@font-face {
  font-family: 'Sarabun-Regular';
  src: url('Sarabun-Regular.eot');
  src: url('Sarabun-Regular.eot?#iefix') format('embedded-opentype'),
       url('Sarabun-Regular.svg#Sarabun-Regular') format('svg'),
       url('Sarabun-Regular.ttf') format('truetype'),
       url('Sarabun-Regular.woff') format('woff'),
       url('Sarabun-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-Italic';
  src: url('Sarabun-Italic.eot');
  src: url('Sarabun-Italic.eot?#iefix') format('embedded-opentype'),
    url('Sarabun-Italic.svg#Sarabun-Italic') format('svg'),
    url('Sarabun-Italic.ttf') format('truetype'),
    url('Sarabun-Italic.woff') format('woff'),
    url('Sarabun-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-Bold';
  src: url('Sarabun-Bold.eot');
  src: url('Sarabun-Bold.eot?#iefix') format('embedded-opentype'),
    url('Sarabun-Bold.svg#Sarabun-Bold') format('svg'),
    url('Sarabun-Bold.ttf') format('truetype'),
    url('Sarabun-Bold.woff') format('woff'),
    url('Sarabun-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-BoldItalic';
  src: url('Sarabun-BoldItalic.eot');
  src: url('Sarabun-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Sarabun-BoldItalic.svg#Sarabun-BoldItalic') format('svg'),
    url('Sarabun-BoldItalic.ttf') format('truetype'),
    url('Sarabun-BoldItalic.woff') format('woff'),
    url('Sarabun-BoldItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}