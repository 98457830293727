@font-face {
  font-family: 'RSU-Bold';
  src: url('RSU-Bold.eot');
  src: url('RSU-Bold.eot?#iefix') format('embedded-opentype'),
    url('RSU-Bold.svg#RSU-Bold') format('svg'),
    url('RSU-Bold.ttf') format('truetype'),
    url('RSU-Bold.woff') format('woff'),
    url('RSU-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RSU-Light';
  src: url('RSU-Light.eot');
  src: url('RSU-Light.eot?#iefix') format('embedded-opentype'),
    url('RSU-Light.svg#RSU-Light') format('svg'),
    url('RSU-Light.ttf') format('truetype'),
    url('RSU-Light.woff') format('woff'),
    url('RSU-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RSU-Regular';
  src: url('RSU-Regular.eot');
  src: url('RSU-Regular.eot?#iefix') format('embedded-opentype'),
    url('RSU-Regular.svg#RSU-Regular') format('svg'),
    url('RSU-Regular.ttf') format('truetype'),
    url('RSU-Regular.woff') format('woff'),
    url('RSU-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}