@font-face {
    font-family: 'IBMPlexSansThai-Medium';
    src: url('IBMPlexSansThai-Medium.eot');
    src: url('IBMPlexSansThai-Medium.eot?#iefix') format('embedded-opentype'),
         url('IBMPlexSansThai-Medium.svg#IBMPlexSansThai-Medium') format('svg'),
         url('IBMPlexSansThai-Medium.ttf') format('truetype'),
         url('IBMPlexSansThai-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'IBMPlexSansThai-Regular';
    src: url('IBMPlexSansThai-Regular.eot');
    src: url('IBMPlexSansThai-Regular.eot?#iefix') format('embedded-opentype'),
      url('IBMPlexSansThai-Regular.svg#IBMPlexSansThai-Regular') format('svg'),
      url('IBMPlexSansThai-Regular.ttf') format('truetype'),
      url('IBMPlexSansThai-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'IBMPlexSansThai-SemiBold';
    src: url('IBMPlexSansThai-SemiBold.eot');
    src: url('IBMPlexSansThai-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('IBMPlexSansThai-SemiBold.svg#IBMPlexSansThai-SemiBold') format('svg'),
      url('IBMPlexSansThai-SemiBold.ttf') format('truetype'),
      url('IBMPlexSansThai-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'IBMPlexSansThaiLooped-Regular';
    src: url('IBMPlexSansThaiLooped-Regular.eot');
    src: url('IBMPlexSansThaiLooped-Regular.eot?#iefix') format('embedded-opentype'),
      url('IBMPlexSansThaiLooped-Regular.svg#IBMPlexSansThaiLooped-Regular') format('svg'),
      url('IBMPlexSansThaiLooped-Regular.ttf') format('truetype'),
      url('IBMPlexSansThaiLooped-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }